.root {
  display: grid;
  place-items: center;
  padding: 10px;
  min-width: 700px;
}

.link {
  color: #dc8100;
  text-decoration: none;
}

.navbar {
  width: 100%;
  padding: 10px;
  background-color: #f5f5f5;
}

.navbar > ul {
  display: flex;
  gap: 20px;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
